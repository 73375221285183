





































import {
  defineComponent,
  PropType,
  ref,
  watch,
  useContext
} from '@nuxtjs/composition-api';
import type { CustomerRegistrationFormData } from '@wemade-vsf/checkout/lib/runtime/types';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { useCompanyRegistration } from '@wemade-vsf/company';

import {
  SfInput,
  SfButton,
  SfLoader
} from '@storefront-ui/vue'

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  props: {
    form: {
      type: Object as PropType<CustomerRegistrationFormData>,
      required: true
    }
  },
  components: {
    SfButton,
    SfInput,
    SfLoader,
    ValidationProvider,
    ValidationObserver
  },
  setup (props, { emit }) {
    const { i18n } = useContext()
    const { loading, getCompanyData, checkCompany } = useCompanyRegistration();
    const formData = ref<CustomerRegistrationFormData>();
    watch(() => props.form, (newForm) => { formData.value = { ...newForm }; }, { immediate: true, deep: true })

    const onFormSubmit = async () => {
      const [result, exists] = await Promise.all([getCompanyData(formData.value.orgNo), checkCompany(formData.value.orgNo)])

      if (exists === true) {
        emit('error', i18n.t('We saw that you are already a customer. Please contact your company administrator.'))
        return
      }
      if (result && !result.bancrupt) {
        formData.value = {
          ...formData.value,
          orgNo: result.orgNo,
          company: result.name,
          address1: result.address1,
          address2: result.address2 || '',
          city: result.city,
          postcode: result.postcode
        }
        emit('submit', formData.value)
      } else {
        emit('error', i18n.t('We were not able to find your company'))
      }
    }

    return {
      loading,
      formData,
      onFormSubmit
    }
  }
})
