





























import {
  defineComponent,
  ref,
  useContext
} from '@nuxtjs/composition-api';
import type { CustomerRegistrationFormData } from '@wemade-vsf/checkout/lib/runtime/types';
import type { CompanyCreateInput } from '@wemade-vsf/magento-api';
import { CountryCodeEnum } from '@wemade-vsf/magento-api';
import FindCompany from 'components/checkout/Account/FindCompany.vue';
import { useUser } from '@wemade-vsf/customer';
import { useCompanyRegistration } from '@wemade-vsf/company';
import { useUiNotification } from '@wemade-vsf/composables';

import {
  SfCheckbox,
  SfHeading
} from '@storefront-ui/vue'


export default defineComponent({
  components: {
    SfHeading,
    SfCheckbox,
    FindCompany,
    RegisterCompany: () => import(/* webpackPrefetch: true */ 'components/checkout/Account/RegisterCompany.vue'),
    RegisterCustomer: () => import(/* webpackPrefetch: true */ 'components/checkout/Account/RegisterCustomer.vue')
  },
  setup (props, { emit }) {
    const { app } = useContext()
    const { register, loading, user, error: userError, load: loadUser } = useUser();
    const { registerCompany } = useCompanyRegistration();
    const isCompanyAccount = ref<boolean>(true);
    const isCompanyDataLoaded = ref<boolean>(false);

    const formData = ref<CustomerRegistrationFormData>({
      company: '',
      orgNo: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      address1: '',
      address2: '',
      postcode: '',
      city: '',
      country: CountryCodeEnum.No,
      hasSeparateShippingAddress: false,
      shipping: {
        company: '',
        firstname: '',
        lastname: '',
        address1: '',
        address2: '',
        postcode: '',
        city: '',
        phone: '',
        country: CountryCodeEnum.No
      },
      password: ''
    })

    const onFindCompanySubmit = (form: CustomerRegistrationFormData) => {
      isCompanyDataLoaded.value = true
      formData.value = form
    }
    
    const getCustomerAddresses = () => {
      let addresses = []
      const billingAddress = {
        company: formData.value.company,
        firstname: formData.value.firstname,
        lastname: formData.value.lastname,
        street: [formData.value.address1, formData.value.address2].filter(Boolean),
        postcode: formData.value.postcode,
        city: formData.value.city,
        country_code: formData.value.country,
        default_billing: true,
        telephone: formData.value.phone,
        ...(formData.value.hasSeparateShippingAddress ? {} : { default_shipping: true })
      }
      addresses.push(billingAddress)
      
      if (formData.value.hasSeparateShippingAddress) {
        const shippingAddress = {
          company: formData.value.shipping.company,
          firstname: formData.value.shipping.firstname,
          lastname: formData.value.shipping.lastname,
          street: [formData.value.shipping.address1, formData.value.shipping.address2].filter(Boolean),
          postcode: formData.value.shipping.postcode,
          city: formData.value.shipping.city,
          country_code: formData.value.shipping.country,
          default_shipping: true,
          telephone: formData.value.shipping.phone,
        }
        addresses.push(shippingAddress)
      }
      return addresses
    }

    const registerCustomer = async () => {
      await register({
        user: {
          email: formData.value.email,
          firstname: formData.value.firstname,
          lastname: formData.value.lastname,
          password: formData.value.password          
        }
      })

      if (!userError.value.register) {
        const addresses = getCustomerAddresses()
        await Promise.all(addresses.map(a => {
          return app.$vsf.$magento.api.createCustomerAddress(a)
        }))
        await loadUser()
      }
    }

    const onCompanyRegistrationSubmit = async (form: CustomerRegistrationFormData) => {
      formData.value = form
      await registerCustomer()

      if (!userError.value.register) {
        const companyData: CompanyCreateInput = {
          company_name: formData.value.company,
          company_email: formData.value.email,
          city: formData.value.city,
          postcode: formData.value.postcode,
          street: [formData.value.address1, formData.value.address2].filter(Boolean).join(', '),
          country_id: String(formData.value.country),
          vat_tax_id: formData.value.orgNo
        }
        await registerCompany(companyData)
        emit('user:registered')
      }
    }

    const onCustomerRegistrationSubmit = async (form: CustomerRegistrationFormData) => {
      formData.value = form
      await registerCustomer()
      emit('user:registered')
    }
    
    const { send: sendNotification } = useUiNotification();
    function displayError(msg: string) {
      sendNotification({
        message: msg,
        icon: 'triangle-exclamation',
        type: 'danger',
        id: Symbol('company-exists')
      })
    }

    return {
      isCompanyAccount,
      isCompanyDataLoaded,
      formData,
      onFindCompanySubmit,
      onCompanyRegistrationSubmit,
      onCustomerRegistrationSubmit,
      displayError
    }
  }
})
